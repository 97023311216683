var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from 'react';
import { Button } from '../Button';
import { classNames, mergePropsWithChildren } from '../utils/helperFunctions';
import { useDetectClickOutside, useDeviceWidth } from '../utils/hooks';
import './MeatballMenu.scss';
import '../Menu/Menu.scss';
import { IconDotsVertical } from '../Icon';
const Container = React.forwardRef(({ children, toggle, position, isToggled, verticalPosition }, ref) => {
    return (_jsx("div", { ref: ref, className: classNames('u-ui-dropdown-menu__dropdown-content', `u-ui-dropdown-menu__dropdown-content--${position}`, `u-ui-dropdown-menu__dropdown-content--${verticalPosition}`, isToggled && 'u-ui-dropdown-menu__dropdown-content--active', 'u-ui-dropdown-menu__dropdown-content--meatball'), children: _jsx("div", { className: "u-ui-dropdown-menu__dropdown-list", children: mergePropsWithChildren(children, { toggle }) }) }));
});
export function MeatballMenu(_a) {
    var { children, disabled = false, hugContent = true, variant = 'tertiary', position = 'left', // left/right
    initialToggle = false, buttonTitle = 'Open menu', hasNotification, size = 'small' } = _a, rest = __rest(_a, ["children", "disabled", "hugContent", "variant", "position", "initialToggle", "buttonTitle", "hasNotification", "size"]);
    const { isMobile } = useDeviceWidth();
    const meatballSize = isMobile ? 'md' : 'sm';
    const [isToggled, toggle] = useState(initialToggle);
    const [verticalPosition, setVerticalPosition] = useState('bottom');
    let btnRef = useRef(null);
    let contentRef = useRef(null);
    let buttonRef = useRef(null);
    const DROPDOWN_MARGIN = 100;
    useDetectClickOutside(isToggled, () => toggle(false), btnRef, contentRef);
    const handleToggle = () => {
        if (disabled)
            return;
        if (!btnRef.current) {
            toggle((prev) => !prev);
            return;
        }
        const buttonRect = btnRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - buttonRect.bottom;
        const direction = spaceBelow < DROPDOWN_MARGIN ? 'top' : 'bottom';
        setVerticalPosition(direction);
        toggle((prev) => !prev);
    };
    return (_jsxs("div", { className: "u-ui-meatball", children: [_jsx("span", { ref: btnRef, children: _jsx(Button, { text: buttonTitle, iconType: "only", size: size, ref: buttonRef, disabled: disabled, onClick: handleToggle, active: isToggled, icon: IconDotsVertical, variant: variant }) }), hasNotification && _jsx("span", { className: "u-ui-meatball__notification" }), isToggled && (_jsx(Container, Object.assign({}, rest, { position: position, ref: contentRef, isToggled: isToggled, toggle: () => toggle(!isToggled), size: meatballSize, verticalPosition: verticalPosition, children: children })))] }));
}
